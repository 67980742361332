document.addEventListener("turbolinks:load", () => {
    $(document).ready(function () {
        $('form.upload-sample-tracking-spreadsheet').submit(function (event) {
            let submitForm = this;
            uploadSpreadSheet(submitForm,
                function(result) {
                },function(xhr, status, error) {
                    var errors = xhr['responseJSON'];

                    $.each(errors, function(index, element) {
                        $('#upload-spreadsheet-modal-error-details').append(
                            $('<dt class="col-sm-3">'+index+'</dt>')
                        )
                        $('#upload-spreadsheet-modal-error-details').append(
                            $('<dd class="col-sm-9">'+element+'</dd>')
                        )
                    });
                },function(xhr, status) {
                    $(submitForm).trigger('reset');
                })

            event.preventDefault();
        });

        function uploadSpreadSheet(submitForm, onSuccess, onError, onComplete) {
            var formData = new FormData();
            formData.append("sample_tracking[file]", $(submitForm).find('input[type=file]')[0].files[0]);

            $.ajax({
                    async: true,
                    type: "POST",
                    url: $(submitForm).attr('action'),
                    data: formData,
                    encode: true,
                    processData: false,
                    contentType: false,
                    beforeSend: function (xhr) {

                        $('#upload-spreadsheet-modal-error-title').hide();
                        $('#upload-spreadsheet-modal-error').hide();
                        $('#upload-spreadsheet-modal-error-details').empty();

                        $('#upload-spreadsheet-modal-success-title').hide();
                        $('#upload-spreadsheet-modal-success').hide();

                        $('#upload-spreadsheet-modal-loading-title').show();
                        $('#upload-spreadsheet-modal-loading-animation').show();

                        xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))
                        $('#spreadsheetUploadModal').modal('show');


                    },
                    success: function (result) {
                        $('#upload-spreadsheet-modal-success-title').show();
                        $('#upload-spreadsheet-modal-success').show();
                        onSuccess(result);
                        location.reload();
                    },
                    error: function (xhr, status, error) {
                        $('#upload-spreadsheet-modal-error-title').show();
                        $('#upload-spreadsheet-modal-error').show();
                        onError(xhr, status, error);
                    },
                    complete: function (xhr, status) {
                        onComplete(xhr, status);
                        $('#upload-spreadsheet-modal-loading-title').hide();
                        $('#upload-spreadsheet-modal-loading-animation').hide();
                        $(submitForm).find('input[type="submit"]').prop('disabled', false);
                    }
                },
            );
        }
    });
});