// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import 'core-js/stable'
import 'regenerator-runtime/runtime'

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")

require("popper.js")
require("bootstrap")
require("bootstrap-select/js/bootstrap-select")

// THIS IS MAKING jQuery AVAILABLE EVEN INSIDE Views FOLDER
global.$ = require("jquery")

// For cocoon
// require("jquery")
require("@nathanvda/cocoon")


import "../stylesheets/application.scss"

import "./samples"

// import 'bootstrap'

// require.context('../images', true)


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
const images = require.context('../images', true)
const imagePath = (name) => images(name, true)

document.addEventListener("turbolinks:load", () => {
    // Enable tooltips everywhere
    $(function () {
        $('[data-toggle="tooltip"]').tooltip()
    })
    $(document).ready(function () {
        $('.datepicker').datepicker({
            format: 'dd/mm/yyyy',
            todayBtn: "linked",
            todayHighlight: true,
            autoclose: true
        });

        // Disable checkboxes with readonly class
        $(document).on('change', 'input[type=checkbox].readonly', function () {
            this.checked = false;
        });
        // Disable radiobutton with readonly class
        $(document).on('change', 'input[type=radio].readonly', function () {
            this.checked = false;
        });
    });
    $('#child-fields').on('cocoon:after-insert', function (e, insertedItem, originalEvent) {
        $('.datepicker').datepicker({
            format: 'dd/mm/yyyy',
            todayBtn: "linked",
            todayHighlight: true,
            autoclose: true
        });
    });

    $('#sample-tracking-body').on('cocoon:after-insert', function (e, insertedItem, originalEvent) {
        $('.datepicker').datepicker({
            format: 'dd/mm/yyyy',
            todayBtn: "linked",
            todayHighlight: true,
            autoclose: true
        });
    });

    $('#antibody-lab-results-body').on('cocoon:after-insert', function (e, insertedItem, originalEvent) {
        $('.datepicker').datepicker({
            format: 'dd/mm/yyyy',
            todayBtn: "linked",
            todayHighlight: true,
            autoclose: true
        });
    });

    $('#child_related_field').on('cocoon:after-insert', function (e, insertedItem, originalEvent) {
        $('.datepicker').datepicker({
            format: 'dd/mm/yyyy',
            todayBtn: "linked",
            todayHighlight: true,
            autoclose: true
        });
    });

    // Upload selected file label for file inputs
    $('input[type="file"]').change(function (e) {
        var fileName = e.target.files[0]?.name;
        $(e.target).siblings('.custom-file-label').text(fileName);
    });

    $('.selectpicker').selectpicker('refresh');
});